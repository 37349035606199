
import { defineComponent, ref, computed, toRefs, watch } from "vue";

export default defineComponent({
    props: {
        numItems: {
            type: Number
            , default: 0
        },
        itemsPerPage: {
            type: Number
            , default: 10
        },
        selectedPage: {
            type: Number,
            required: false,
            default: () => 0
        }
    },
    components: {},
    emits: ['change-page-size', 'handleClickPage'],
    setup(props, context){
        const { numItems, itemsPerPage, selectedPage } = toRefs(props);
        let numPages = ref(0);
        let arrayPages: number[] = [];
        let currentPage = ref(1);
        let numDisplacement = 1;
        let lastPages: number[] = [];
        let min = 0;
        let max = 0;

        watch(numItems, (value) => {
            lastPages = [];
            arrayPages = [];
            
            let mod = numItems.value % itemsPerPage.value;
            numPages.value = (numItems.value - mod)/itemsPerPage.value + (mod > 0 ? 1 : 0);
            let iterator=1;
            while(iterator <= numPages.value){
                if(!arrayPages.some(p => p == iterator)) {
                    arrayPages.push(iterator);
                }
                iterator++;
            }
        });

        watch(selectedPage, (value) => {
            currentPage.value = selectedPage.value;
        });

        const handleClickPage = (page) => {
            currentPage.value = page;
            context.emit('handleClickPage', currentPage.value);
        }

        const goToFirstPage = () => {
            handleClickPage(1);
        }

        const goToPreviousPage = () => {
            handleClickPage(currentPage.value - 1);
        }

        const goToNextPage = () => {
            handleClickPage(currentPage.value + 1);
        }

        const goToLastPage = () => {
            handleClickPage(numPages.value);
        }

        const changeSelectPage = (newPage: string) => {
            const page = Number.isNaN(+newPage) ? 10 : +newPage
            context.emit('change-page-size', page)
         }

        const disablePreviousBtn = computed(() => {
            return currentPage.value == 1;
        });

        const disableNextBtn = computed(() => {
            return currentPage.value == renderPages.value[renderPages.value.length - 1];
        });

        const renderPages = computed(() => {
            if(numPages.value > itemsPerPage.value) {
                if(currentPage.value == 1) {
                    min = currentPage.value;
                    max = itemsPerPage.value;
                } else if(currentPage.value == numPages.value) {
                    min = numPages.value - (itemsPerPage.value - numDisplacement);
                    max = numPages.value;
                } else {
                    let minP = Math.min(...lastPages);
                    let maxP = Math.max(...lastPages);

                    if(currentPage.value == minP && currentPage.value != 1) {
                        min -= numDisplacement;
                        max -= numDisplacement;
                    }

                    if(currentPage.value == maxP) {
                        min += numDisplacement;
                        max += numDisplacement   
                    }
                }

                lastPages = arrayPages
                    .filter(item => item >= min)
                    .filter(item => item <= max)
                    .map(page => page);
                return lastPages;
                
            } else {
                lastPages = [...arrayPages];
                return arrayPages;
            }
            
        });

        const currentSelectedPage = computed(() => {
            return currentPage.value;
        });

        return {
            arrayPages, 
            currentPage, 
            renderPages,
            currentSelectedPage,
            disablePreviousBtn,
            disableNextBtn,
            handleClickPage,
            goToFirstPage,
            goToPreviousPage,
            goToNextPage,
            goToLastPage
            , changeSelectPage
        }
    }
});
