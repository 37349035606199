<template>
    <div 
        class="modal fade"
        id="modalChangePrices"
        tabindex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-centered mw-650px" >
            
            <div class="modal-content rounded">
                <div class="modal-header"
                    id="modalChangePrices_header"
                >
                    <h2 class="fw-bolder">{{$t('TITLE')}}</h2>
                    <div
                        id="modalChangePrices_close"
                        data-bs-dismiss="modal"
                        class="btn btn-icon btn-sm btn-active-icon-primary"
                    >
                        <span class="svg-icon svg-icon-1">
                        <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y px-10 px-lg-15 pb-15">
                    <div class="fv-row rowDivControl" style="width: 510px;">
                        <div class="fv-row">
                            <input 
                                id="fileInput" 
                                type="file" 
                                accept=".xlsx"
                                class="form-control" 
                                style="width: 560px;" 
                                ref="fileInput" 
                                @change="onSelectFile"
                            >
                        </div>
                    </div>
                </div>
                <div class="modal-footer flex-right">
                     <button
                        type="reset"
                        class="btn btn-light me-3"
                        id="modalChangePrices_close"
                        data-bs-dismiss="modal"
                        ref="btnClose"
                    >
                        {{ $t('CANCEL_BTN') }}
                    </button>
                    <button class="btn btn-lg btn-primary" @click="saveNewPrices" :disabled="!haveSelectedFile">
                        <span class="indicator-label">
                            {{ $t('SAVE_BTN') }}
                            <span class="svg-icon svg-icon-3 ms-2 me-0">
                                <inline-svg src="icons/duotune/arrows/arr064.svg" />
                            </span>
                        </span>
                        <span v-if="isSaving" class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
    name: "change-prices",
    setup() {
        let btnClose = ref(null);
        let loading = ref(false);
        let fileInput = ref(null);
        let selectedFile = ref(null);

        const haveSelectedFile = computed(() => selectedFile.value != null)
        
        const initDefaults = () => {
            if(fileInput.value) {
                selectedFile.value = null;
                fileInput.value.value = null;
                loading.value = false;
            }
        }

        const onSelectFile = (file) => {
            selectedFile.value = file;
        }

        const saveNewPrices = async() => {
            loading.value = true;
            const fd = new FormData();
            fd.append('Layout', selectedFile.value)
            await ApiService.uploadFile("Products", "UpdatePrice", fd).then(res => {
                console.log(res)
                if(btnClose.value) {
                    loading.value = false;
                    btnClose.value.click();
                }
            });              
            
        }

        return {
            // modalForm
            btnClose
            , fileInput
            , haveSelectedFile
            , initDefaults
            , onSelectFile
            , saveNewPrices
        }
    },
});
</script>

<style lang="scss">
    .itemControl {
        width: 553px;
    }
</style>

<i18n>
{
    "es": {
        "TITLE": "Cambiar Precios",
        "SAVE_BTN": "Actualizar",
        "CANCEL_BTN": "Cancelar",
        "COLNAMES": {
            "COST_INCREASE": "% Incremento al costo",
            "M1": "M1",
            "M2": "M2",
            "M3": "M3"
        },
        "COLTOOLTIPS": {
            "COST_INCREASE": "Incremento del costo del artículo",
            "M1": "Actualización del precio público",
            "M2": "Actualización del precio aluminiero",
            "M3": "Actualización del precio mayoreo"
        }
    }
}
</i18n>