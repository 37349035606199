export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
        "FIELDS": {
          "WAREHOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacén"])},
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
          "CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
          "BAR_ALTERNATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterno"])},
          "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
          "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familia"])},
          "CLASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clase"])},
          "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
          "SUPPLIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor"])}
        },
        "PH": {
          "WAREHOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacén"])},
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
          "CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
          "BAR_ALTERNATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterno"])},
          "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
          "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familia"])},
          "CLASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clase"])},
          "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
          "SUPPLIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor"])}
        }
      }
    }
  })
}
